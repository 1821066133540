<template>
  <!--MOBILE-->
  <div class="d-lg-none">
    <div class="nav-item" :class="{active: item.isActive && (!item.sub)}">
      <div class="row m-0 align-items-center parent" @click="showSub" v-if="item.sub" :class="{activeParent: isSub || item.isActive}">
        <div class="col ps-5 py-2 item">
          {{item.displayName}}
        </div>
        <div class="col-auto pe-4" v-if="item.sub">
          <ios-arrow-down-icon class="inline-icon small cursor" v-if="isSub" />
          <ios-arrow-forward-icon class="inline-icon small cursor" v-else />
        </div>
      </div>
      <div class="row m-0 align-items-center" @click="goTo(item.link)" v-else>
        <div class="col ps-5 py-2 item">
          {{item.displayName}}
        </div>
      </div>
      <transition name="slide">
        <div class="row m-0" v-if="item.sub && (isSub)">
          <div class="col-12 py-1 subitem" v-for="(subItem, key) in item.sub" :key="key" :class="{active: subItem.isActive}" @click="goTo(subItem.link)">
            <div class="row m-0">
              <div class="col ps-5 item" @click.self="goTo(subItem.link)">
                {{subItem.displayName}}
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
  <!--END MOBILE-->
  <!--DESKTOP-->
  <div @mouseover="isHover = true" @mouseout="isHover = false" class="d-none d-lg-block">
    <div class="nav-item" :class="{active: item.isActive}" @click.self="goTo(item.link)">
      <span class="px-0" @click.self="goTo(item.link)">
        {{item.displayName}} <span class="sub-icon" v-if="item.sub"><ios-arrow-down-icon /></span>
      </span>
      <div class="nav_border d-none d-lg-block"></div>
      <div class="submenu" v-if="item.sub">
        <div v-show="isHover" >
          <div class="p-3 subitem" v-for="(subItem, key) in item.sub" :key="key" :class="{first: key === 0, last: lastItem === subItem}" @click="goTo(subItem.link)">
            {{subItem.displayName}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--END DESKTOP-->
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'NavItem',
  props: ['item'],
  components: {
    'ios-arrow-down-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-down.vue')),
    'ios-arrow-forward-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-forward.vue'))
  },
  emits: ['closeMenu'],
  data () {
    return {
      isHover: false,
      isSub: false
    }
  },
  computed: {
    lastItem () {
      if (this.item.sub) {
        var lastItem = this.item.sub.slice(-1).pop()
        return lastItem
      } else {
        return null
      }
    },
    activeItem () {
      console.log('this.$route', this.$route.path)
      let activePage = ''
      if (this.$route.name === this.routeName) {
        activePage = this.routeName
      } else if (this.item.sub) {
        this.item.sub.find(obj => {
          if (obj.routeName === this.$route.name) {
            activePage = obj.routeName
          }
        })
      } else {
        activePage = ''
      }
      return activePage
    }
  },
  methods: {
    goTo (val) {
      window.scrollTo(0, 0)
      this.isHover = false
      this.isActive = false
      this.$emit('closeMenu')
      this.$router.push('/' + val)
    },
    showSub () {
      this.isSub = !this.isSub
    }
  }
}
</script>

<style scoped>
  .nav-item {
    position: relative;
    color: #fff;
    font-family: "quicksand_bold", sans-serif;
    font-weight: bold;
    font-size: 19px;
  }
  .nav-item .item:hover {
    color: #fff;
    background-color: var(--gold-color);
  }
  .nav-item .parent .item:hover {
    color: #fff;
    background-color: transparent;
  }
  .nav-item.active {
    color: #fff;
    background-color: var(--gold-color);
  }
  .nav-item .parent.activeParent {
    color: var(--green-color-dark);
    background-color: var(--gold-color);
  }
  .nav-item .parent.activeParent .item {
    color: var(--green-color-dark);
  }
  .nav-item:hover {
    cursor: pointer;
  }
  .sub-icon {
    position: relative;
    font-size: 14px;
    line-height: 1;
  }
  .subitem {
    font-size: 17px;
    font-weight: 500;
  }
  .subitem:hover, .subitem.active {
    color: #fff;
    background-color: var(--gold-color);
  }
  .slide-enter-active {
    transition: all 0.5s ease;
  }
  .slide-leave-active {
    transition: all 0.5s ease;
  }
  .slide-enter-from {
    transform: translateX(-100%);
    opacity: 1;
  }
  .slide-leave-to {
    transform: translateX(-100%);
    opacity: 1;
  }
  @media (min-width: 992px) {
    .nav-item {
      font-size: 18px;
      padding: 12px;
      line-height: 20px;
    }
    .nav-item.active {
      color: var(--gold-color);
      background-color: transparent;
    }
    .nav-item:hover {
      color: var(--gold-color);
      cursor: pointer;
    }
    .nav-item .nav_border {
      padding-top: 13px;
      border-bottom: 4px solid transparent;
    }
    .nav-item.active .nav_border, .nav-item:hover .nav_border {
      border-bottom-color: var(--gold-color);
    }
    .sub-icon {
      position: relative;
      font-size: 14px;
      line-height: 1;
    }
    .submenu  {
      position: absolute;
      top: calc(100% - 9px);
      padding-top: 19px;
      height: 1px;
      left: 50%;
      transform: translateX(-50%);
      min-width: calc(100% + 2rem);
      background-color: transparent;
      z-index: 2;
    }
    .subitem {
      background: #fff;
      color: var(--green-color-dark);
      font-size: 13px;
      font-weight: 500;
      white-space: nowrap;
    }
    .subitem.first {
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
    }
    .subitem.last {
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
    }
    .subitem:hover {
      background: var(--green-color-dark);
      color: var(--gold-color);
    }
  }
  @media (min-width: 1200px) {
    .nav-item {
      font-size: 18px;
      padding: 12px 18px;
      line-height: 20px;
    }
  }
</style>
